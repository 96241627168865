<template>
  <!--MODAL DELETE DAY-->
  <b-modal
    id="delete-day-modal"
    :title="$t('broadcast.deleteDay')"
    :ok-disabled="$v.$invalid"
    :ok-title="$t('broadcast.delete')"
    :cancel-title="$t('table.cancel')"
    :busy="isModalBusy"
    @ok.prevent="showModalDeleteDayConfirm"
    @show="clearData()"
  >
    <b-form-group :label="$t('table.day')" label-for="delete-day-datepicker-buttons-from">
      <datepicker-wrapper id="delete-day-datepicker-buttons-from" v-model="deleteDay" required :disabled="isModalBusy" />
    </b-form-group>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import convertDate from '@/filters/convertDate';

export default {
  components: { DatepickerWrapper },
  filters: { convertDate },
  props: {
    channel: {
      required: true,
      type: Object,
    },
  },
  // defineEmits<{
  //   (e: 'dayDeleted'): void,
  // }>()
  data() {
    return {
      deleteDay: '',
      isModalBusy: false,
    };
  },
  validations: {
    deleteDay: { required },
  },
  methods: {
    clearData() {
      this.deleteDay = '';
    },

    async showModalDeleteDayConfirm() {
      const body = this.$createElement('p', {
        domProps: {
          innerHTML: `${this.$i18n.t('broadcast.confirmDeleteDay')} <span class="text-danger">${convertDate(this.deleteDay)}</span> ?`,
        },
      });
      const modalResult = await this.$bvModal
        .msgBoxConfirm(body, {
          title: this.$i18n.t('broadcast.deleteDay'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'delete-day-confirm-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      this.postDeleteDay();
    },

    async postDeleteDay() {
      this.isModalBusy = true;
      await this.$store.dispatch('POST_BROADCAST_DELETE_DAY', {
        data: {
          channel: this.channel.id,
        },
        date: {
          date: this.deleteDay,
        },
        handler: (res) => {
          this.$notify({
            type: 'success',
            duration: 2000,
            title: this.$i18n.t('alert.deletedDay'),
            text: `<p class="mb-0 font-weight-bold">${this.$i18n.t('alert.deleted')}: </p>
                  ${this.$i18n.t('alert.programReleasesGC')}: ${res.data.result?.program_releases}<br/>
                  ${this.$i18n.t('alert.blocksGC')}: ${res.data.result?.blocks}`,
          });
          this.$bvModal.hide('delete-day-modal');
          this.$emit('dayDeleted');
          this.clearData();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },
  },
};
</script>
